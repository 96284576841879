import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { userInfo } from 'os';

@Injectable()
export class DataProvider {

  public data: any;
  public TABLE = "dummy";

  constructor(public http: HttpClient) {
  }

  getData(user_name) {
    return this.http.get('./assets/data/newQuestions.json');
  }

  public getImageSets(user_name) {

      return new Promise(resolve => {
          this.getData(user_name).subscribe((data) => {
        this.data = data;
        resolve(data);
      });
    });
  }

  public async addToGD(post_data: any): Promise<any> {
    console.log(post_data);
    try {
      // 'http://127.0.0.1:8000/add'
      const header = new HttpHeaders();
      header.set('Access-Control-Allow-Origin', '*');
      //return await this.http.post('https://asv055hou0.execute-api.eu-central-1.amazonaws.com/dev/add2sql/'
         // + this.TABLE, JSON.stringify(post_data), { headers: header }).toPromise();
        return await this.http.post('https://dl.win.tue.nl/add2sql/' + this.TABLE, JSON.stringify(post_data)).toPromise();
        // return await this.http.post('http://localhost:8000/add2sql/' + this.TABLE, JSON.stringify(post_data)).toPromise();
    } catch (err) {

      alert("error");
    }
  }

}
