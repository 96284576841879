import { Component, OnInit } from '@angular/core';
import { DataProvider } from '../provider/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  allImages: any;
  currentImages: any;
  gen: Generator;
  public user_id;
    // TODO fix this to be filled by annotators
  public user_name: string;
  done = false;
  counter = -1;
 
    
  constructor(
    public dataProvider: DataProvider,
  ) {
  

  if (window.location.href.includes("firstname"))
  {    
    this.user_name = window.location.href.split('=')[1];  

	this.user_id = Math.floor(Math.random() * 1000000)   

	
    this.dataProvider.getImageSets(this.user_name).then((res) => {
      this.allImages = res;

      function* generateNewSet(set) {

        let i = set.length;
        while (i > 0) {
          i--;
          yield set[set.length-i-1];
        }
      }
      this.gen = generateNewSet(this.allImages);
      this.showNextImage();

    });
	   
	
    
  }
  }


  ngOnInit() {
    }

    

  showNextImage() {

    const nextImage = this.gen.next();
      console.log(nextImage);
     

    if (nextImage.done) {
      this.showDone();
    }
    this.currentImages = nextImage.value;

    this.counter += 1;
  }

  showDone() {
    this.done = true;
    }


    public async select(choice) {
	
       //alert("Hello" + this.user_name + " " + this.user_id)
      

    if (choice === 'A') {
      const result = {
      Anchor: this.currentImages[1],
      option_A: this.currentImages[2],
      option_B: this.currentImages[0],
      closest_to_anchor: this.currentImages[2],
      date: new Date(),
      user: this.user_id,
      user_name: this.user_name
      }

    try {
      this.dataProvider.addToGD(result);
      this.showNextImage();

    } catch (err) {
      console.log('ERROR', err);
      alert('oops, something went wrong');
    }}


    else if (choice === 'B') {
  
      const result = {
      Anchor: this.currentImages[2],
      option_A: this.currentImages[0],
      option_B: this.currentImages[1],
      closest_to_anchor: this.currentImages[0],
      date: new Date(),
      user: this.user_id,
      user_name: this.user_name
      }

    try {
      this.dataProvider.addToGD(result);
      this.showNextImage();

    } catch (err) {
      console.log('ERROR', err);
      alert('oops, something went wrong');
    }}



    else if (choice === 'C') {

      const result = {
      Anchor: this.currentImages[0],
      option_A: this.currentImages[1],
      option_B: this.currentImages[2],
      closest_to_anchor: this.currentImages[1],
      date: new Date(),
          user: this.user_id,
          user_name: this.user_name
      }

    try {
      this.dataProvider.addToGD(result);
      this.showNextImage();

    } catch (err) {
      console.log('ERROR', err);
      alert('oops, something went wrong');
    }}

  }

}
